
jQuery(function ($) {
    // You can use $ inside the wrapper

    
    
    $(".applybtn").click(function (){
        $("#vacancy-application").slideDown();
        $(".applybtn").addClass("d-none");
    });

    $(window).scroll(function(){

        if ($(this).scrollTop() > 50) {
            $('#masthead').addClass('navScrolling');
            $(".header-logo").width(0);
            $(".roundel").width("50px");

        } else {
            $('#masthead').removeClass('navScrolling');
            $(".header-logo").width("auto");
            $(".roundel").width(0);
        }
    });

});